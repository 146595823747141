const notFoundContent = [
  {
    id: 1,
    title: '404',
    subtitle: 'Uh-oh!',
    description: 'It’s not rocket science, but it’ll take a few moments to fix',
    button: 'Take me home',
    image: '/images/page-not-found/not-found-1.svg'
  },
  {
    id: 2,
    title: '404',
    subtitle: 'Whoops',
    description: 'Bananas.  Good for your body, bad for your sole',
    button: 'Take me home',
    image: '/images/page-not-found/not-found-2.svg'
  },
  {
    id: 3,
    title: '404',
    subtitle: 'Destination Not Found',
    description: 'Not out to lunch, but also nowhere to be found….',
    button: 'Take me home',
    image: '/images/page-not-found/not-found-3.svg'
  },
  {
    id: 4,
    title: '404',
    subtitle: 'Lights Out',
    description: 'Fido fetched the wrong chew toy, but we’re on it!',
    button: 'Take me home',
    image: '/images/page-not-found/not-found-4.svg'
  }
]


export default notFoundContent;
