<template>
  <section class="not-found">
    <figure class="not-found__content">
      <figcaption class="not-found__text">
        <h1 class="not-found__title">{{ pageData.title }}</h1>
        <h2 class="not-found__subtitle">{{ pageData.subtitle }}</h2>
        <p class="not-found__description">
          {{ pageData.description }}
        </p>
        <a :href="pageOrigin" class="not-found__button">
          {{ pageData.button }}
        </a>
      </figcaption>
      <img :src="pageData.image" class="not-found__image" :alt="pageData.title">
    </figure>
  </section>
</template>

<script>
import notFoundContent from "@/helpers/notFoundPage";

export default {
  name: "NotFoundPage",
  computed: {
    pageOrigin() {
      return document.location.origin;
    },
    pageData() {
      const randomIndex = Math.floor(Math.random() * notFoundContent.length)
      return notFoundContent[randomIndex]
    }
  }
}
</script>
